import React from 'react';
import { Breadcrumb } from 'antd';
import AsyncComponent from './utils/asyncComponent';
import { Link } from 'react-router-dom';

const NotFound = AsyncComponent(()=>import('./pages/NotFound'))

const routes = [
    {
        path: '/',
        exact: true,
        component: AsyncComponent(()=>import('./pages/Dashboard/Analysis')),
        requiresAuth: false,
        breadcrumb: <Breadcrumb>
            <Breadcrumb.Item>
            <Link to="/">数据分析</Link>
            </Breadcrumb.Item>
        </Breadcrumb>,
    },
    // 登录
    {
        path: '/login',
        exact: true,
        component: AsyncComponent(()=>import('./pages/Login')),
        requiresAuth: false,
        breadcrumb: <Breadcrumb>
            <Breadcrumb.Item>登录</Breadcrumb.Item>
        </Breadcrumb>,
    },
    //数据总览
    {
        path: '/dashboard/analysis',
        exact: true,
        component: AsyncComponent(()=>import('./pages/Dashboard/Analysis')),
        requiresAuth: false,
        breadcrumb: <Breadcrumb>
            <Breadcrumb.Item>
            <Link to="/">数据分析</Link>
            </Breadcrumb.Item>
        </Breadcrumb>,
    },
    {
        path: '/usermanage/userlist',
        exact: true,
        component: AsyncComponent(()=>import('./pages/UserManage/UserList')),
        requiresAuth: false,
        breadcrumb: <Breadcrumb>
            <Breadcrumb.Item>
            <Link to="/">用户列表</Link>
            </Breadcrumb.Item>
        </Breadcrumb>,
    },
    {
        path: '/usermanage/userlist/cpadetail',
        exact: true,
        component: AsyncComponent(()=>import('./pages/UserManage/UserList/CPADetail')),
        requiresAuth: false,
        breadcrumb: <Breadcrumb>
            <Breadcrumb.Item>
            <Link to="/">用户列表</Link>
            </Breadcrumb.Item>
        </Breadcrumb>,
    },
    {
        path: '/usermanage/userlist/cpldetail',
        exact: true,
        component: AsyncComponent(()=>import('./pages/UserManage/UserList/CPLDetail')),
        requiresAuth: false,
        breadcrumb: <Breadcrumb>
            <Breadcrumb.Item>
            <Link to="/">用户列表</Link>
            </Breadcrumb.Item>
        </Breadcrumb>,
    },
    {
        path: '/usermanage/invitelist',
        exact: true,
        component: AsyncComponent(()=>import('./pages/UserManage/InviteList')),
        requiresAuth: false,
        breadcrumb: <Breadcrumb>
            <Breadcrumb.Item>
            <Link to="/">邀请列表</Link>
            </Breadcrumb.Item>
        </Breadcrumb>,
    },
    {
        path: '/usermanage/invitelist/invitedetail',
        exact: true,
        component: AsyncComponent(()=>import('./pages/UserManage/InviteList/Detail')),
        requiresAuth: false,
        breadcrumb: <Breadcrumb>
            <Breadcrumb.Item>
            <Link to="/">邀请列表</Link>
            </Breadcrumb.Item>
        </Breadcrumb>,
    },
    {
        path: '/usermanage/withdrawlist',
        exact: true,
        component: AsyncComponent(()=>import('./pages/UserManage/WithdrawList')),
        requiresAuth: false,
        breadcrumb: <Breadcrumb>
            <Breadcrumb.Item>
            <Link to="/">提现列表</Link>
            </Breadcrumb.Item>
        </Breadcrumb>,
    },
    {
        path: '/usermanage/devicelist',
        exact: true,
        component: AsyncComponent(()=>import('./pages/UserManage/DeviceList')),
        requiresAuth: false,
        breadcrumb: <Breadcrumb>
            <Breadcrumb.Item>
            <Link to="/">软件列表</Link>
            </Breadcrumb.Item>
        </Breadcrumb>,
    },
    /** 广告管理 */
    {
        path: '/advertisement/adresource',
        exact: true,
        component: AsyncComponent(()=>import('./pages/Advertisement/AdResource')),
        requiresAuth: false,
        breadcrumb: <Breadcrumb>
            <Breadcrumb.Item>
            <Link to="/">广告资源库</Link>
            </Breadcrumb.Item>
        </Breadcrumb>,
    },
    {
        path: '/advertisement/adlist',
        exact: true,
        component: AsyncComponent(()=>import('./pages/Advertisement/AdList')),
        requiresAuth: false,
        breadcrumb: <Breadcrumb>
            <Breadcrumb.Item>
            <Link to="/">广告列表</Link>
            </Breadcrumb.Item>
        </Breadcrumb>,
    },
    {
        path: '/advertisement/cparules',
        exact: true,
        component: AsyncComponent(()=>import('./pages/Advertisement/CPARules')),
        requiresAuth: false,
        breadcrumb: <Breadcrumb>
            <Breadcrumb.Item>
            <Link to="/">CPA任务规则</Link>
            </Breadcrumb.Item>
        </Breadcrumb>,
    },
    {
        path: '/advertisement/cplrules',
        exact: true,
        component: AsyncComponent(()=>import('./pages/Advertisement/CPLRules')),
        requiresAuth: false,
        // breadcrumb: <Breadcrumb>
        //     <Breadcrumb.Item>
        //     <Link to="/">CPL任务规则</Link>
        //     </Breadcrumb.Item>
        // </Breadcrumb>,
    },
    {
        path: '/advertisement/cpatasklist',
        exact: true,
        component: AsyncComponent(()=>import('./pages/Advertisement/CPATaskList')),
        requiresAuth: false,
    },
    {
        path: '/advertisement/cpltasklist',
        exact: true,
        component: AsyncComponent(()=>import('./pages/Advertisement/CPLTaskList')),
        requiresAuth: false,
    },
    {
        path: '/advertisement/cplrulesreward',
        exact: true,
        component: AsyncComponent(()=>import('./pages/Advertisement/CPLRulesReward')),
        requiresAuth: false,
    },
    {
        path: '/advertisement/cplrulesrewardlist',
        exact: true,
        component: AsyncComponent(()=>import('./pages/Advertisement/CPLRulesRewardList')),
        requiresAuth: false,
    },
    {
        path: '/advertisement/try-rules-reward-list',
        exact: true,
        component: AsyncComponent(()=>import('./pages/Advertisement/TryRulesRewardList')),
        requiresAuth: false,
    },
    {
        path: '/advertisement/dylist',
        exact: true,
        component: AsyncComponent(()=>import('./pages/Advertisement/dylist')),
        requiresAuth: false,
    },

/** 活动管理 */
{
    path: '/activity/challenge',
    exact: true,
    component: AsyncComponent(()=>import('./pages/activity/challenge')),
    requiresAuth: false,
},
{
    path: '/activity/list',
    exact: true,
    component: AsyncComponent(()=>import('./pages/activity/list')),
    requiresAuth: false,
},
{
    path: '/service/list',
    exact: true,
    component: AsyncComponent(()=>import('./pages/service/list')),
    requiresAuth: false,
},

    
/** 客户&财务 */
{
    path: '/finance/customer',
    exact: true,
    component: AsyncComponent(()=>import('./pages/Finance/Customer')),
    requiresAuth: false,
    breadcrumb: <Breadcrumb>
        <Breadcrumb.Item>
        <Link to="/">客户管理</Link>
        </Breadcrumb.Item>
    </Breadcrumb>,
},
{
    path: '/finance/bills',
    exact: true,
    component: AsyncComponent(()=>import('./pages/Finance/Bills')),
    requiresAuth: false,
    
},
{
    path: '/finance/statement',
    exact: true,
    component: AsyncComponent(()=>import('./pages/Finance/statement')),
    requiresAuth: false,
    
},
{
    path: '/finance/income',
    exact: true,
    component: AsyncComponent(()=>import('./pages/Finance/Income')),
    requiresAuth: false,
    breadcrumb: <Breadcrumb>
        <Breadcrumb.Item>
        <Link to="/">客户管理</Link>
        </Breadcrumb.Item>
    </Breadcrumb>,
},
/** 数据统计 */
{
    path: '/statistics/bills',
    exact: true,
    component: AsyncComponent(()=>import('./pages/Statistics/FinanceBills')),
    requiresAuth: false,
    breadcrumb: <Breadcrumb>
        <Breadcrumb.Item>
        <Link to="/">财务报表</Link>
        </Breadcrumb.Item>
    </Breadcrumb>,
},
    // {
    //     path: '/statistics/platformdata',
    //     exact: true,
    //     component: AsyncComponent(()=>import('./pages/Statistics/PlatformData')),
    //     requiresAuth: false,
    //     breadcrumb: <Breadcrumb>
    //         <Breadcrumb.Item>
    //         <Link to="/">广告平台数据</Link>
    //         </Breadcrumb.Item>
    //     </Breadcrumb>,
    // },
    {
        path: '/statistics/addverreport',
        exact: true,
        component: AsyncComponent(()=>import('./pages/Statistics/AddverReport')),
        requiresAuth: false,
        breadcrumb: <Breadcrumb>
            <Breadcrumb.Item>
            <Link to="/">广告平台数据</Link>
            </Breadcrumb.Item>
        </Breadcrumb>,
    },
    {
        path: '/statistics/cpl-report',
        exact: true,
        component: AsyncComponent(()=>import('./pages/Statistics/CplReport')),
        requiresAuth: false,
        breadcrumb: <Breadcrumb>
            <Breadcrumb.Item>
            <Link to="/">CPL数据</Link>
            </Breadcrumb.Item>
        </Breadcrumb>,
    },

    {
        path: '/statistics/cpa-report',
        exact: true,
        component: AsyncComponent(()=>import('./pages/Statistics/CpaReport')),
        requiresAuth: false,
        breadcrumb: <Breadcrumb>
            <Breadcrumb.Item>
            <Link to="/">CPL数据</Link>
            </Breadcrumb.Item>
        </Breadcrumb>,
    },

    /** 渠道管理 */
    {
        path: '/channel/channellist',
        exact: true,
        component: AsyncComponent(()=>import('./pages/Channel/ChannelList')),
        requiresAuth: false,
        breadcrumb: <Breadcrumb>
            <Breadcrumb.Item>
            <Link to="/">渠道管理</Link>
            </Breadcrumb.Item>
        </Breadcrumb>,
    },
    
    {
        path: '/channel/channelreport',
        exact: true,
        component: AsyncComponent(()=>import('./pages/Channel/ChannelReport')),
        requiresAuth: false,
        breadcrumb: <Breadcrumb>
            <Breadcrumb.Item>
            <Link to="/">渠道报表</Link>
            </Breadcrumb.Item>
        </Breadcrumb>,
    },
    {
        path: '/channel/talentlist',
        exact: true,
        component: AsyncComponent(()=>import('./pages/Channel/TalentList')),
        requiresAuth: false,
        breadcrumb: <Breadcrumb>
            <Breadcrumb.Item>
            <Link to="/">达人数据</Link>
            </Breadcrumb.Item>
        </Breadcrumb>,
    },
    // {
    //     path: '/channel/talentsettlement',
    //     exact: true,
    //     component: AsyncComponent(()=>import('./pages/Channel/TalentSettlement')),
    //     requiresAuth: false,
    //     breadcrumb: <Breadcrumb>
    //         <Breadcrumb.Item>
    //         <Link to="/">达人报表</Link>
    //         </Breadcrumb.Item>
    //     </Breadcrumb>,
    // },

    // {
    //     path: '/channel/talentdetail',
    //     exact: true,
    //     component: AsyncComponent(()=>import('./pages/Channel/TalentDetail')),
    //     requiresAuth: false,
    //     breadcrumb: <Breadcrumb>
    //         <Breadcrumb.Item>
    //         <Link to="/">达人报表</Link>
    //         </Breadcrumb.Item>
    //     </Breadcrumb>,
    // },
    {
        path: '/channel/config',
        exact: true,
        component: AsyncComponent(()=>import('./pages/Channel/config')),
        requiresAuth: false,
        breadcrumb: <Breadcrumb>
            <Breadcrumb.Item>
            <Link to="/">达人配置</Link>
            </Breadcrumb.Item>
        </Breadcrumb>,
    },
    {
        path: '/account/list',
        exact: true,
        component: AsyncComponent(()=>import('./pages/Account/List')),
        requiresAuth: false,
        breadcrumb: <Breadcrumb>
            <Breadcrumb.Item>
            <Link to="/">员工管理</Link>
            </Breadcrumb.Item>
        </Breadcrumb>,
    },
    {
        path: '/tigerfood/list',
        exact: true,
        component: AsyncComponent(()=>import('./pages/TigerFood/List')),
        requiresAuth: false,
        breadcrumb: <Breadcrumb>
            <Breadcrumb.Item>
            <Link to="/">每日任务</Link>
            </Breadcrumb.Item>
        </Breadcrumb>,
    },
    {
        path: '/tigerfood/drawrewad',
        exact: true,
        component: AsyncComponent(()=>import('./pages/TigerFood/DrawRewad')),
        requiresAuth: false,
        breadcrumb: <Breadcrumb>
            <Breadcrumb.Item>
            <Link to="/"> 签到任务</Link>
            </Breadcrumb.Item>
        </Breadcrumb>,
    },

    {
        path: '/task/tasklist',
        exact: true,
        component: AsyncComponent(()=>import('./pages/Task/TaskList')),
        requiresAuth: false,
        breadcrumb: <Breadcrumb>
            <Breadcrumb.Item>
            <Link to="/">每日任务</Link>
            </Breadcrumb.Item>
        </Breadcrumb>,
    },
    {
        path: '/task/signinlist',
        exact: true,
        component: AsyncComponent(()=>import('./pages/Task/SignInList')),
        requiresAuth: false,
        breadcrumb: <Breadcrumb>
            <Breadcrumb.Item>
            <Link to="/"> 签到任务</Link>
            </Breadcrumb.Item>
        </Breadcrumb>,
    },

    // {
    //     path: '/sync/query/',
    //     exact: true,
    //     component: AsyncComponent(()=>import('./pages/sync/query')),
    //     requiresAuth: false,
    //     breadcrumb: <Breadcrumb>
    //         <Breadcrumb.Item>
    //         <Link to="/"> 签到任务</Link>
    //         </Breadcrumb.Item>
    //     </Breadcrumb>,
    // },

    /** 未找到的页面 */
    {
        path: '*',
        component: NotFound,
        requiresAuth: false,
        breadcrumb: <Breadcrumb>
            <Breadcrumb.Item>
            <Link to="/">物料系统</Link>
            </Breadcrumb.Item>
        </Breadcrumb>,
    }
]

export default routes