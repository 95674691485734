import React, { useEffect, useState } from 'react';
import {Dropdown,Menu,Button} from 'antd';
import { getLocal } from '../../utils';


const Header = ()=>{
    const [username, setName] = useState("");
    // const role = getLocal("role")

    useEffect(()=>{
        setName(getLocal("username"));
    },[])

    return (
        <div className="main__header">
            <Dropdown
                overlay={(
                    <Menu>
                        <Menu.Item>
                            <Button type="link" danger onClick={()=>{
                                localStorage.clear();
                                window.location.href="/login";
                            }}>退出登录</Button>
                        </Menu.Item>
                    </Menu>
                )}
            >
                <Button type="link">{username||"用户"}</Button>
            </Dropdown>
        </div>
    )
}

export default Header;