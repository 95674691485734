import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import { Menu, Button } from 'antd';
import {
    MenuUnfoldOutlined,
    MenuFoldOutlined,
} from '@ant-design/icons';
import menuConfig from './config'

const { SubMenu } = Menu;

const rootSubmenuKeys = menuConfig.map(item=>item.key);
const MenuComponent = () => {
    const [collapsed, setCollapsed] = useState();
    const [openKeys, setOpenKeys] = useState([rootSubmenuKeys[0]]);

    const path = window.location.pathname;

    /** 展开其他菜单 */
    const onOpenChange = keys => {
        const latestOpenKey = keys.find(key => openKeys.indexOf(key) === -1);
        if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
            setOpenKeys(keys);
        } else {
            setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
        }
    };

    /** 当前展开菜单 */
    useEffect(()=>{
        setOpenKeys([menuConfig?.find(item=>path?.includes(item.key))?.key])
    },[path])

    return (
        <div
            style={{width: collapsed?'80px':'200px'}}
            className="main__menu"
        >
            <Menu
                openKeys={openKeys}
                selectedKeys={path}
                mode="inline"
                theme="dark"
                inlineCollapsed={collapsed}
                style={{
                    position: 'relative',
                    height: '100%'
                }}
                onOpenChange={onOpenChange}
            >
                {
                    menuConfig.map(item=>{
                        if(item.children){
                            return (
                                <SubMenu key={item.key} icon={<item.icon />} title={item.name}>
                                    {
                                        item.children.map(sub=>{
                                            return (
                                                <Menu.Item key={sub.key}>
                                                    <Link to={sub.key}>{sub.name}</Link>
                                                </Menu.Item>
                                            )
                                        })
                                    }
                                </SubMenu>
                            )
                        }
                        return (
                            <Menu.Item key={item.key} icon={<item.icon />}>
                                <Link to={item.key}>{item.name}</Link>
                            </Menu.Item>
                        )
                    })
                }
            </Menu>
            <Button
                type="primary"
                onClick={() => { setCollapsed(!collapsed) }}
                style={{
                    position: 'absolute',
                    bottom: 0,
                    width: '100%',
                }}
            >
                    {React.createElement(collapsed ? MenuUnfoldOutlined : MenuFoldOutlined)}
                </Button>
        </div>
    )
}

export default MenuComponent;