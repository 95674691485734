/*
 * @Description:
 * @Autor: elvin
 * @Date: 2020-05-10 20:09:20
 * @LastEditors: elvin
 * @LastEditTime: 2021-04-11 20:59:18
 */
import React from 'react'
import {Layout} from 'antd';
import renderRoutes from './utils/renderRoutes'
import Menu from './common/Menu';
import Header from './common/Header'
import routes from "./router.js";
import { Switch  } from "react-router-dom";

class App extends React.Component{
    render(){
        return (
            <Layout className="main__page">
                <Menu />
                <Layout className="main__layout">
                    <Header />
                    <Layout.Content className="main__content">
                            <Switch>
                                {renderRoutes(routes)}
                            </Switch>
                    </Layout.Content>
                </Layout>
            </Layout>
        )
    }
}

export default App
